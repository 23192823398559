<template>
  <div>
    <div class="flix-row">
      <div class="flix-col-md-6">
        <div class="flix-form-group">
          <label class="flix-html-label flix-required flix-html-required">{{ $tc('message.company', 1) }}</label>
          <input type="text" class="flix-form-control" v-model="user.company" spellcheck="false" />
        </div>
      </div>
      <div class="flix-col-md-6">
        <div class="flix-form-group">
          <label class="flix-html-label">{{ $tc('message.vat', 1) }}</label>
          <input type="text" class="flix-form-control" readonly="true" v-if="user.vat" v-model="user.vat.vat_number" spellcheck="false" />
        </div>
        <div class="flix-form-group">
          <editBtn :settings="{block: false}" :onClick="function(){ onChange('vat') }" >
            <template v-slot:text>
              {{ $t('message.edit', {name: $tc('message.vat', 1)}) }}
            </template>
          </editBtn>
        </div>
      </div>
    </div>
    <div class="flix-row">
      <div class="flix-col-md-6">
        <div class="flix-form-group">
          <label class="flix-html-label">{{ $tc('message.first_name', 1) }} <span class="flix-text-danger">*</span></label>
          <input v-on:keyup="checkSaveButton" type="text" class="flix-form-control" v-model="user.first_name" spellcheck="false" />
        </div>
      </div>
      <div class="flix-col-md-6">
        <div class="flix-form-group">
          <label class="flix-html-label">{{ $tc('message.last_name', 1) }} <span class="flix-text-danger">*</span></label>
          <input v-on:keyup="checkSaveButton" type="text" class="flix-form-control" v-model="user.last_name" spellcheck="false" />
        </div>
      </div>
    </div>
    <div class="flix-row">
      <div class="flix-col-md-3">
        <div class="flix-form-group">
          <label class="flix-html-label">{{ $tc('message.country', 1) }} <span class="flix-text-danger">*</span></label>
          <select v-on:keyup="checkSaveButton" class="flix-form-control" v-model="user.country">
            <option v-for="(country, index) in $t('countries')" :value="index" :key="index">({{ index.toUpperCase() }}) {{ country }}</option>
          </select>
        </div>
      </div>
      <div class="flix-col-md-3">
        <div class="flix-form-group">
          <label class="flix-html-label">{{ $tc('message.streetNumber', 1) }} <span class="flix-text-danger">*</span></label>
          <input v-on:keyup="checkSaveButton" type="text" class="flix-form-control" v-model="user.adress" spellcheck="false" />
        </div>
      </div>
      <div class="flix-col-md-3">
        <div class="flix-form-group">
          <label class="flix-html-label">{{ $tc('message.zip', 1) }} <span class="flix-text-danger">*</span></label>
          <input v-on:keyup="checkSaveButton" type="text" class="flix-form-control" v-model="user.zip" spellcheck="false" />
        </div>
      </div>
      <div class="flix-col-md-3">
        <div class="flix-form-group">
          <label class="flix-html-label">{{ $tc('message.city', 1) }} <span class="flix-text-danger">*</span></label>
          <input v-on:keyup="checkSaveButton" type="text" class="flix-form-control" v-model="user.city" spellcheck="false" />
        </div>
      </div>
    </div>
    <div class="flix-row">
      <div class="flix-col-md-6">
        <div class="flix-form-group">
          <label class="flix-html-label">{{ $tc('message.tel', 1) }} <span class="flix-text-danger">*</span></label>
          <input type="text" class="flix-form-control" readonly="true" v-model="user.tel" spellcheck="false" />
        </div>
        <div class="flix-form-group">
          <editBtn :settings="{block: false}" :onClick="function(){ onChange('tel') }">
            <template v-slot:text>
              {{ $t('message.edit', {name: $tc('message.tel', 1)}) }}
            </template>
          </editBtn>
        </div>
      </div>
      <div class="flix-col-md-6">
        <div class="flix-form-group">
          <label class="flix-html-label">{{ $tc('message.email', 1) }} <span class="flix-text-danger">*</span></label>
          <input type="text" class="flix-form-control" readonly="true" v-model="user.email" spellcheck="false" />
        </div>
        <div class="flix-form-group">
          <editBtn :settings="{block: false}" :onClick="function(){ onChange('email') }">
            <template v-slot:text>
              {{ $t('message.edit', {name: $tc('message.email', 1)}) }}
            </template>
          </editBtn>
        </div>
      </div>
    </div>
    <div class="flix-row" :key="saveBtn">
      <div class="flix-col-md-12">
        <div class="flix-form-group">
          <saveBtn :class="{'flix-disabled': !saveBtn}" :settings="{block: true}" :onClick="function () { if (saveBtn) { saveData(user) } }">
            <template v-slot:text>
              {{ $t('message.save', {name: $tc('message.profile', 1)}) }}
            </template>
          </saveBtn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    saveData: Function,
    onChange: Function
  },
  data () {
    return {
      user: JSON.parse(JSON.stringify(this.$store.getters.user)),
      saveBtn: false
    }
  },
  methods: {
    checkSaveButton () {
      var required = [
        this.user.first_name,
        this.user.last_name,
        this.user.country,
        this.user.adress,
        this.user.zip,
        this.user.city,
        this.user.email
      ]
      this.saveBtn = true
      required.forEach(function (v) {
        if (!v) {
          this.saveBtn = false
        }
      }.bind(this))
    }
  },
  mounted () {
    if (typeof this.user.vat === 'undefined') {
      this.user.vat = {
        vat_number: ''
      }
    }
    this.checkSaveButton()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
